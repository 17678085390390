<template>
    <div>
        <Banner></Banner>
<!--        <Services></Services>-->
<!--        <Funfacts></Funfacts>-->
        <Feedbacks></Feedbacks>
        <GreenBanner></GreenBanner>

<!--		<section class="ready-to-talk">-->
<!--			<div class="container">-->
<!--				<h3>Ready to talk?</h3>-->
<!--				<p>Our team is here to answer your question about StartP</p>-->
<!--				<a href="#" class="btn btn-primary">Contact Us</a>-->
<!--				<span><a href="#">Or, get started now with a free trial</a></span>-->
<!--			</div>-->
<!--		</section>-->
    </div>
</template>

<script>
import Banner from './contact-request/Banner';
import GreenBanner from './contact-request/GreenBanner';
// import BoxesArea from './contact-request/BoxesArea';
// import FeaturesArea from './contact-request/FeaturesArea';
// import Features from './contact-request/Features';
// import Services from './contact-request/Services';
// import Team from './contact-request/Team';
// import Funfacts from './contact-request/Funfacts';
// import Pricing from './contact-request/Pricing';
import Feedbacks from './feedback/Feedbacks';
// import Partner from './contact-request/Partner';
// import Blog from './contact-request/Blog';
export default {
    name: 'ContactRequest',
    title: 'Contact Request',
    components: {
        Banner,
        // Services,
        // Team,
        // Funfacts,
        Feedbacks,
        GreenBanner,
        // Partner,
        // Blog
    }
}
</script>
