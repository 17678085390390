<template>
    <div>
        <Title></Title>

        <section class="blog-details-area ptb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <Sidebar></Sidebar>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <Description></Description>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Title from './faq/Title';
import Description from './faq/Description';
import Sidebar from './faq/Sidebar';
export default {
    name: 'Faq',
    title: 'Faq',
    components: {
        Title,
        Description,
        Sidebar
    }
}
</script>
