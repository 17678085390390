import VueRouter from 'vue-router';
// Pages
import HomePage from '../../views/interface/pages/HomePage';
import ContactUs from '../../views/interface/pages/ContactUs';
import AboutUs from '../../views/interface/pages/AboutUs';
import ContactRequest from '../../views/interface/pages/ContactRequest';
import Faq from '../../views/interface/pages/Faq';
import TermsMain from '../../views/interface/pages/TermsMain';
import TermsPayment from '../../views/interface/pages/TermsPayment';
import Privacy from '../../views/interface/pages/Privacy';

import Maintenance from '../../views/interface/pages/Maintenance';
import Development from '../../views/interface/pages/Development';
import WebHosting from '../../views/interface/pages/WebHosting';
import GraphicStudio from '../../views/interface/pages/GraphicStudio';

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    // linkActiveClass: "active",
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes: [
        {path: '/', component: HomePage},
        {path: '/about-us', component: AboutUs},
        {path: '/contact-us', component: ContactUs},
        {path: '/contact-request', component: ContactRequest},
        {path: '/faq', component: Faq},
        {path: '/terms/main', component: TermsMain},
        {path: '/terms/payment', component: TermsPayment},
        {path: '/privacy-policy', component: Privacy},

        {path: '/services/development', component: Development},
        {path: '/services/maintenance', component: Maintenance},
        {path: '/services/cloud-hosting', component: WebHosting},
        {path: '/services/graphic-studio', component: GraphicStudio},
    ]
});
