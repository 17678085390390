<template>
    <section class="ready-to-talk">
        <div class="container">
            <h3>Ready to talk?</h3>
            <p>Our team is here to answer your question about Start</p>
            <a href="/contact-us" class="btn btn-primary">Contact Us</a>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ReadyToTalk'
}
</script>
