<template>
    <div id="why-you-are-not-another-freelancer-network">
        <h3>Why you are not another freelancer network?</h3>
        <p>Due to the fact that freelance networks could not fully meet the needs of customers, including the correct division of the project, high quality and commitment to the correct implementation of the project, support after design and implementation of subsequent project development, guarantee the expertise of the executor and quality control supervisor. <strong>We are not a freelance network.</strong></p>
    </div>
</template>

<script>
export default {
    name: 'why-you-are-not-another-freelancer-core'
}
</script>
