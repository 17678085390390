<template>
    <div id="can-i-review-my-tasks">
        <h3>Can I review my tasks?</h3>
        <p><strong>Short Answer:</strong> Yes. This time in the true sense of the word</p>
        <p><strong>Details :</strong> Your project is based on software design methods. This means that you do not have to wait for the project to be delivered to you to check that. During the implementation of the project, you can see the progress of the work and make the necessary changes whenever needed so that the project can proceed properly.</p>
    </div>
</template>

<script>
export default {
    name: 'can-i-review-my-tasks'
}
</script>
