<template>
    <div>
        <Banner></Banner>
        <BoxesArea></BoxesArea>
        <ServicesArea></ServicesArea>
        <ServicesArea2></ServicesArea2>
<!--        <ServicesArea3></ServicesArea3>-->
        <Features></Features>
<!--        <Team></Team>-->
<!--        <Funfacts></Funfacts>-->
<!--        <Works></Works>-->
<!--        <Pricing></Pricing>-->
        <Feedbacks></Feedbacks>

		<section class="ready-to-talk">
			<div class="container">
				<h3>Ready to talk?</h3>
				<p>Our team is here to answer your question about Start</p>
				<a href="/contact-us" class="btn btn-primary">Contact Us</a>
			</div>
		</section>

<!--        <Partner></Partner>-->
<!--        <Blog></Blog>-->
    </div>
</template>

<script>
import Banner from './home-page/Banner';
import BoxesArea from './home-page/BoxesArea';
import ServicesArea from './home-page/ServicesArea';
import ServicesArea2 from './home-page/ServicesArea2';
import ServicesArea3 from './home-page/ServicesArea3';
import Features from './home-page/Features';
// import Team from './home-page/Team';
// import Funfacts from './home-page/Funfacts';
// import Works from './home-page/Works';
// import Pricing from './home-page/Pricing';
import Feedbacks from './feedback/Feedbacks';
// import Partner from './home-page/Partner';
// import Blog from './home-page/Blog';
export default {
    name: 'HomePage',
    title: 'Home Page',
    components: {
        Banner,
        BoxesArea,
        ServicesArea,
        ServicesArea2,
        ServicesArea3,
        Features,
        // Team,
        // Funfacts,
        // Works,
        // Pricing,
        Feedbacks,
        // Partner,
        // Blog
    }
}
</script>
