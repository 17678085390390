<template>
    <div>
		<section class="solutions-area ptb-80">
			<div class="container">
                <div class="section-title">
                    <h2>Our Featured Services that We Provide</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-solutions-box">
                            <div class="icon">
                                <img src="../../../../assets/interface/img/icon4.png" alt="image">
                            </div>
                            <h3><a href="#">Robotic Automation</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><i data-feather="plus-circle"></i> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-solutions-box">
                            <div class="icon">
                                <img src="../../../../assets/interface/img/icon5.png" alt="image">
                            </div>
                            <h3><a href="#">Cognitive Automation</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><i data-feather="plus-circle"></i> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-solutions-box">
                            <div class="icon">
                                <img src="../../../../assets/interface/img/icon6.png" alt="image">
                            </div>
                            <h3><a href="#">Cognitive Engagement</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><i data-feather="plus-circle"></i> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-solutions-box">
                            <div class="icon">
                                <img src="../../../../assets/interface/img/icon7.png" alt="image">
                            </div>
                            <h3><a href="#">Security & Surveillance</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><i data-feather="plus-circle"></i> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-solutions-box">
                            <div class="icon">
                                <img src="../../../../assets/interface/img/icon6.png" alt="image">
                            </div>
                            <h3><a href="#">Automatic Optimization</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><i data-feather="plus-circle"></i> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-solutions-box">
                            <div class="icon">
                                <img src="../../../../assets/interface/img/icon7.png" alt="image">
                            </div>
                            <h3><a href="#">Data Analysts</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><i data-feather="plus-circle"></i> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-solutions-box">
                            <div class="icon">
                                <img src="../../../../assets/interface/img/icon5.png" alt="image">
                            </div>
                            <h3><a href="#">Health & Manufacturing</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><i data-feather="plus-circle"></i> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-solutions-box">
                            <div class="icon">
                                <img src="../../../../assets/interface/img/icon4.png" alt="image">
                            </div>
                            <h3><a href="#">Software Engineers</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><i data-feather="plus-circle"></i> Learn More</a>
                        </div>
                    </div>
                </div>
			</div>

			<div class="shape1"><img src="../../../../assets/interface/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../../assets/interface/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
		</section>
        <!-- End Our Solutions Area -->
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>
