<template>
    <div class="agency-main-banner">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 pl-lg-5">
                    <div class="agency-banner-content">
                        <span class="sub-title">We are creative at</span>
                        <h1>Visual Creations</h1>
                        <p></p>
                        <a href="/contact-request" class="btn btn-secondary">Get Started</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="agency-banner-image">
                        <img src="../../../../assets/interface/img/agency-image/banner-img.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="shape2 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>

        <div class="shape3"><img src="../../../../assets/interface/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>

    </div>
</template>

<script>
export default {
    name: 'Banner'
}
</script>
