<template>
    <!-- Start Why Choose US Area -->
    <section class="why-choose-us ptb-80 pt-0">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="section-title">
                        <h2>Why Choose Us</h2>
                        <div class="bar"></div>
                        <p>All our plans are backed with interesting, useful and innovative features that help to complete and complement your web hosting experience with us.
                           We are always on the look out to bring you the best the industry has to offer.</p>
                    </div>

                    <div class="why-choose-us-image">
                        <img src="../../../../assets/interface/img/why-choose-us-image/man-stand.png" class="wow fadeInLeft" alt="image">
                        <img src="../../../../assets/interface/img/why-choose-us-image/database.png" class="wow fadeInRight" alt="image">
                        <img src="../../../../assets/interface/img/services-left-image/cercle-shape.png" class="rotateme" alt="image">
                        <img src="../../../../assets/interface/img/why-choose-us-image/main-static.png" class="main-pic wow fadeInUp" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <feather type="clock"></feather>
                                </div>
                                <h3>29 Day First Money Back Guarantee</h3>
                                <p>We guarantee 29-day unconditional 100% money-back on first hosting payment.
                                   Then you can try our services by your own, and place your trust here with no charge.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <feather type="dollar-sign"></feather>
                                </div>
                                <h3>Best Cost-Performance Ratio in Clouds</h3>
                                    <p>Not even best cost-performance ratio, We are using infrastructures that proves you faster performance with must great cloud-hosting companies that you know </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <feather type="server"></feather>
                                </div>
                                <h3>Complete Maintenance</h3>
                                <p>We will handle whole server management issues, then you will have nothing to worry</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <feather type="zoom-in"></feather>
                                </div>
                                <h3>Expert Supervision</h3>
                                <p>All tasks and subjects will be performed by the technical team, under expert supervision</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <feather type="package"></feather>
                                </div>
                                <h3>Concentrated Services</h3>
                                <p>Domain, SSL, Hosting,
                                   Security, Support and Development,
                                   Graphic Design and Mobile Application
                                   </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <feather type="cpu"></feather>
                                </div>
                                <h3>Scalability & Always Available</h3>
                                <p>Instantly add CPUs, memories and storage with a click,
                                    or the API to programmatically scale your server at the rush hours</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Why Choose US Area -->
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>
