<template>
    <div>
        <Banner></Banner>
        <Solutions></Solutions>
        <About></About>
        <Funfacts></Funfacts>
<!--        <Projects></Projects>-->
<!--        <Pricing></Pricing>-->
        <Feedbacks></Feedbacks>
        <ReadyToTalk></ReadyToTalk>
    </div>
</template>

<script>
import Banner from './graphic-studio/Banner';
import Solutions from './graphic-studio/Solutions';
import About from './graphic-studio/About';
import Funfacts from './graphic-studio/Funfacts';
// import Projects from './graphic-studio/Projects';
// import Pricing from './graphic-studio/Pricing';
import Feedbacks from './feedback/Feedbacks';
import ReadyToTalk from './graphic-studio/ReadyToTalk';
export default {
    name: 'GraphicStudio',
    title: 'Graphic Studio',
    components: {
        Banner,
        Solutions,
        About,
        Funfacts,
        // Projects,
        // Pricing,
        Feedbacks,
        ReadyToTalk,
    }
}
</script>
