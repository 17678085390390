<template>
    <section class="cta-area ptb-80">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6">
                    <div class="cta-content">
                        <h3>Sign up for cloud hosting today!</h3>
                    </div>
                </div>

                <div class="col-lg-5 col-md-6">
                    <div class="cta-right-content">
                        <div class="hosting-price">
                            <span>Starting at only</span>
                            <h4>$4.75/mo*</h4>
                        </div>

                        <div class="buy-btn">
                            <a href="/contact-us" class="btn btn-primary">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ReadyToTalk'
}
</script>
