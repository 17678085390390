<template>
    <div id="what-are-my-options-to-get-supported">
        <h3>What are my options to get supported?</h3>
        <p>You have the support of application registration consultants when defining a project. When the project is divided into smaller tasks, you have the support of your technical supervisor until the tasks are executed and the project is delivered correctly. you have the support of the project quality control. At the time of delivery, you have the support of your project manager and technical manager in order to expand the project or solve possible problems during execution.</p>
    </div>
</template>

<script>
export default {
    name: 'what-are-my-options-to-get-supported'
}
</script>
