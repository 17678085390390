<template>
    <div id="how-does-datarivers-work">
        <h3>How does Datarivers work?</h3>
        <p>By Describing your project after registering here, we analyze your description and make the call with you.</p>
    </div>
</template>

<script>
export default {
    name: 'how-does-datarivers-work'
}
</script>
