<template>
    <!-- Start Boxes Area -->
    <section class="boxes-area">
        <div class="container">
            <div class="row">

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-eb6b3d">
                        <div class="icon">
                            <feather type="aperture"></feather>
                        </div>
                        <h3>Integrated Service</h3>
                        <p>We break down your projects and done each piece with the relevant expert</p>
<!--                        <p>Once the project is implemented, all focus will be on regular support</p>-->
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-c679e3">
                        <div class="icon">
                            <feather type="users"></feather>
                        </div>
                        <h3>Team Work</h3>
                        <p>Projects are engineered by technical teams under expert supervision</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box">
                        <div class="icon">
                            <feather type="server"></feather>
                        </div>
                        <h3>Cloud Servers</h3>
                        <p>The hosts used by <strong>Datarivers</strong> are 100% managed by the hosting team</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-f78acb">
                        <div class="icon">
                            <feather type="shield"></feather>
                        </div>
                        <h3>Code Security</h3>
                        <p>All programs and codes will be tested and analyzed by a security team</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- End Boxes Area -->
</template>

<script>
export default {
    name: 'BoxesArea'
}
</script>
