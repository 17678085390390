<template>
    <section class="contact-info-area ptb-80">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="contact-info-box">
                        <div class="icon">
                            <feather type="mail"></feather>
                        </div>
                        <h3>Mail Here</h3>
                        <p>The supervisor? <a href="mailto:supervisor@datarivers.org">supervisor@datarivers.org</a></p>
                        <p>Not sure who? <a href="mailto:info@datarivers.org">info@datarivers.org</a></p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="contact-info-box">
                        <div class="icon">
                            <feather type="phone"></feather>
                        </div>
                        <h3>Call Here</h3>
                        <p>SuperVisor Direct <a href="tel:+31645396996">(+31) 6453 96996</a></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ContactInfo"
}
</script>
