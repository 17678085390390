<template>
    <div>
        <Banner></Banner>
<!--        <Partner></Partner>-->
        <WhyChooseUs></WhyChooseUs>
        <Services></Services>
        <Cta></Cta>
        <Funfacts></Funfacts>
        <Features></Features>
        <FeaturesTwo></FeaturesTwo>
        <Feedbacks></Feedbacks>
        <ReadyToTalk></ReadyToTalk>
<!--        <Blog></Blog>-->
    </div>
</template>

<script>
import Banner from './maintenance/Banner';
// import Partner from './maintenance/Partner';
import WhyChooseUs from './maintenance/WhyChooseUs';
import Services from './maintenance/Services';
import Cta from './maintenance/Cta';
import Funfacts from './maintenance/Funfacts';
import Features from './maintenance/Features';
import FeaturesTwo from './maintenance/FeaturesTwo';
import Feedbacks from './feedback/Feedbacks';
import ReadyToTalk from './maintenance/ReadyToTalk';
// import Blog from './maintenance/Blog';
export default {
    name: 'Maintenance',
    title: 'Maintenance',
    components: {
        Banner,
        // Partner,
        WhyChooseUs,
        Services,
        Cta,
        Funfacts,
        Features,
        FeaturesTwo,
        Feedbacks,
        ReadyToTalk,
        // Blog,
    }
}
</script>
