<template>
    <div id="what-guarantee-do-i-have-that-my-project-goes-well">
        <h3>What guarantee do I have that my project goes well?</h3>
        <p>Your project is based on modern software design methods. This means that you do not have to wait until the end of the project to be informed of the result. The fact that you are with the project step by step and you are informed about the work process is the best guarantee in terms of implementation and quality.</p>
    </div>
</template>

<script>
export default {
    name: 'what-guarantee-do-i-have-that-my-project-goes-well'
}
</script>
