<template>
    <!-- Start Main Banner -->
    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5">
                            <div class="hero-content">
                                <h1>Join 14+ Luxury Websites</h1>
                                <p>Join our clients, those use Datarivers IT Services <br> and watch the formation of
                                    <span class="text-primary">ideas</span> and the <span class="text-primary">growth</span> of business</p>

                                <a href="/contact-request" class="btn btn-primary">I want to join</a>
                            </div>
                        </div>

                        <div class="col-lg-6 offset-lg-1">
                            <div class="banner-image">
                                <img src="../../../../assets/interface/img/banner-image/deal.png" class="wow fadeInUp" v-wow data-wow-delay="1s" alt="main-pic">
                                <img src="../../../../assets/interface/img/banner-image/deal.png" class="wow fadeInDown" v-wow data-wow-delay="1s" alt="main-pic">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../../../../assets/interface/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../../../assets/interface/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape5"><img src="../../../../assets/interface/img/shape5.png" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
    </div>
    <!-- End Main Banner -->
</template>

<script>
export default {
    name: 'Banner'
}
</script>

