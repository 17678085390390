<template>
    <div id="do-i-own-what-delivered-to-me">
        <h3>Do I own what delivered to me?</h3>
        <p>Once your project is fully delivered and payment is completed, you are granted all intellectual property rights, including but not limited to, copyrights for the work delivered from us.</p>
    </div>
</template>

<script>
export default {
    name: 'do-i-own-what-delivered-to-me'
}
</script>
