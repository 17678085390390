<template>
    <div id="do-i-need-to-break-down-my-own-project-myself">
        <h3>Do I need to break down my own project myself?</h3>
        <p><strong>Short Answer:</strong> No</p>
        <p><strong>Details :</strong> From the moment the project starts, you can have our advice. Including how to divide the project into different tasks, so that the project implementation process best suits your time and facilities.</p>
    </div>
</template>

<script>
export default {
    name: 'do-i-need-to-break-down-my-own-project-myself'
}
</script>
