<template>
    <div id="welcome">
        <p>Welcome to Datarivers.org.</p>
        <p>
            The following terms and conditions (these "Terms and Conditions"), govern your access to and use of the Datarivers website and mobile application, including any content, functionality and services offered on or through www.datarivers.org or the Datarivers mobile application (the "Site") by Datarivers International Ltd. (address) as applicable. Datarivers International Ltd. is collectively referred here to as "Datarivers" "we" or "us" and “you” “user” or “client” means you as an user of the Site and mobile application.
            Please read the Terms of Service carefully before you start to use the Site. By using the Site, opening an account or by clicking to accept or agree to the Terms of Service when this option is made available to you, you accept and agree, on behalf of yourself or on behalf of your employer or any other entity (if applicable), to be bound and abide by these Terms of Service, Datarivers Payment Terms, found here (“Payment Terms”), and our Privacy Policy, found here, each of which is incorporated herein by reference. If you do not want to agree to these Terms of Service or the Privacy Policy, you must not access or use the Site. For more detailed policies surrounding the activity and usage on the Site, please access the designated articles herein.
            This Site is offered and available to users who are 13 years of age or older. If you are under 13 you may not use this Site or the Datarivers services. By using this Site, you represent and warrant that you are of legal age to form a binding contract and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Site.
            The original language of these Terms of Service, as well as all other texts throughout the Site, is English. Datarivers makes available this translation for convenience only. In case of conflicts between the original English version and any translation, the English version shall prevail.
        </p>
    </div>
</template>

<script>
export default {
    name: 'Welcome'
}
</script>
