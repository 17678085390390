<template>
    <aside class="widget-area" id="secondary">
        <!--
            <section class="widget widget_search">
                <form class="search-form">
                    <label>
                        <span class="screen-reader-text">Search for:</span>
                        <input type="search" class="search-field" placeholder="Search...">
                    </label>
                    <button type="submit"><feather type="search"></feather></button>
                </form>
            </section>
        -->

        <section class="widget widget_categories">
            <h3 class="widget-title">Questions</h3>
            <ul>
                <li><a href="#how-does-datarivers-work">How does Datarivers work?</a></li>
                <li><a href="#how-can-i-get-credit">How can I get credit?</a></li>
                <li><a href="#do-i-need-to-break-down-my-own-project-myself">Do I need to break down my own project myself?</a></li>
                <li><a href="#lets-say-i-just-have-an-idea-how-can-i-figure-out-who-can-do-what-for-my-idea">Let’s say I just have an idea, how should I figure out “who can do what” for my idea?</a></li>
                <li><a href="#how-can-i-follow-my-project">How can I follow my project?</a></li>
                <li><a href="#can-i-review-my-tasks">Can I review my tasks?</a></li>
                <li><a href="#how-can-i-assign-a-project">How can I assign a project?</a></li>
                <li><a href="#what-guarantee-do-i-have-that-my-project-goes-well">What guarantee do I have that my project goes well?</a></li>
                <li><a href="#why-you-are-not-another-freelancer-network">Why you are not another freelancer network?</a></li>
                <li><a href="#do-i-need-to-give-you-all-my-server-access-information">Do I need to give you all my server access information?</a></li>
                <li><a href="#is-my-personal-information-safe">Is my personal information safe?</a></li>
                <li><a href="#what-are-my-options-to-get-supported">What are my options to get supported?</a></li>
                <li><a href="#do-i-own-what-delivered-to-me">Do I own what delivered to me?</a></li>
            </ul>
        </section>
    </aside>
</template>

<script>
export default {
    name: 'Sidebar'
}
</script>
