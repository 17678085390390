<template>
    <div class="blog-details-desc">
<!--
        <div class="article-image">
            <img src="../../../../assets/interface/img/terms/1.png" alt="image">
        </div>
-->
        <div class="article-content">
            <how_does_datarivers_work></how_does_datarivers_work>
            <how_can_i_get_credit></how_can_i_get_credit>
            <do_i_need_to_break_down_my_own_project_myself></do_i_need_to_break_down_my_own_project_myself>
            <lets_say_i_just_have_an_idea_how_can_i></lets_say_i_just_have_an_idea_how_can_i>
            <how_can_i_follow_my_project></how_can_i_follow_my_project>
            <can_i_review_my_tasks></can_i_review_my_tasks>
            <how_can_i_assign_a_project></how_can_i_assign_a_project>
            <what_guarantee_do_i_have_that_my_project_goes_well></what_guarantee_do_i_have_that_my_project_goes_well>
            <why_you_are_not_another_freelancer_core></why_you_are_not_another_freelancer_core>
            <do_i_need_to_give_you_all_my_server_access_information></do_i_need_to_give_you_all_my_server_access_information>
            <is_my_personal_information_safe></is_my_personal_information_safe>
            <what_are_my_options_to_get_supported></what_are_my_options_to_get_supported>
            <do_i_own_what_delivered_to_me></do_i_own_what_delivered_to_me>
        </div>
    </div>
</template>

<script>
import how_does_datarivers_work from './items/q01'
import how_can_i_get_credit from './items/q02'
import do_i_need_to_break_down_my_own_project_myself from './items/q03'
import lets_say_i_just_have_an_idea_how_can_i from './items/q04'
import how_can_i_follow_my_project from './items/q05'
import can_i_review_my_tasks from './items/q06'
import how_can_i_assign_a_project from './items/q07'
import what_guarantee_do_i_have_that_my_project_goes_well from './items/q08'
import why_you_are_not_another_freelancer_core from './items/q09'
import is_my_personal_information_safe from './items/q10'
import what_are_my_options_to_get_supported from './items/q11'
import do_i_own_what_delivered_to_me from './items/q12'
import do_i_need_to_give_you_all_my_server_access_information from './items/q13'
export default {
    name: 'Description',
    components: {
        how_does_datarivers_work,
        how_can_i_get_credit,
        do_i_need_to_break_down_my_own_project_myself,
        lets_say_i_just_have_an_idea_how_can_i,
        how_can_i_follow_my_project,
        can_i_review_my_tasks,
        how_can_i_assign_a_project,
        what_guarantee_do_i_have_that_my_project_goes_well,
        why_you_are_not_another_freelancer_core,
        is_my_personal_information_safe,
        what_are_my_options_to_get_supported,
        do_i_own_what_delivered_to_me,
        do_i_need_to_give_you_all_my_server_access_information,
    }
}
</script>
