<template>
    <!-- Start Design & Development Services -->
    <section class="services-area ptb-80">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 services-left-image">
                    <img src="../../../../assets/interface/img/services-left-image/big-monitor.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="big-monitor">
                    <img src="../../../../assets/interface/img/services-left-image/creative.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="creative">
                    <img src="../../../../assets/interface/img/services-left-image/developer.png" class="wow fadeInLeft" v-wow data-wow-delay="0.6s" alt="developer">
                    <img src="../../../../assets/interface/img/services-left-image/flower-top.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="flower-top">
                    <img src="../../../../assets/interface/img/services-left-image/small-monitor.png" class="wow bounceIn" v-wow data-wow-delay="0.6s" alt="small-monitor">
                    <img src="../../../../assets/interface/img/services-left-image/small-top.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="small-top">
                    <img src="../../../../assets/interface/img/services-left-image/table.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="table">
                    <img src="../../../../assets/interface/img/services-left-image/target.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="target">
                    <img src="../../../../assets/interface/img/services-left-image/cercle-shape.png" class="bg-image rotateme" alt="shape">

                    <img src="../../../../assets/interface/img/services-left-image/main-pic.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="main-pic">
                </div>

                <div class="col-lg-6 col-md-12 services-content">
                    <div class="section-title">
                        <h2>Software Development</h2>
                        <div class="bar"></div>
                        <p>Your ideas will be analyzed consultants of <strong>Datarivers</strong> and will be developed by the technical team, under expert supervision.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="shopping-cart"></feather> E-commerce developments
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="code"></feather> Laravel web developments
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="globe"></feather> Wordpress plugins
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="globe"></feather> Joomla plugins
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="smartphone"></feather> Android/iOS applications
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="smartphone"></feather> PWA mobile applications
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="pen-tool"></feather> UserInterface (UI) designs
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="layout"></feather> Responsive design
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="check-circle"></feather> SDLC life-cycle
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="box">
                                <feather type="check-circle"></feather> Supervisor Control
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Design & Development Services -->
</template>

<script>
export default {
    name: 'ServicesArea'
}
</script>
