<template>
    <div id="is-my-personal-information-safe">
        <h3>Is my personal information safe?</h3>
        <p>All your information is completely safe with us. including but not limited to, your personal and contact information, your company information, and your project information. All will be kept strictly confidential and even the developer will have access to this information as needed.</p>
    </div>
</template>

<script>
export default {
    name: 'is-my-personal-information-safe'
}
</script>
