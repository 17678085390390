<template>
    <section class="agency-cta-area ptb-80">
        <div class="container">
            <div class="agency-cta-content">
                <h2>Do you have any projects?</h2>
                <a href="/contact-us" class="btn btn-gradient">Contact Us</a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ReadyToTalk'
}
</script>
