<template>
    <div class="item">
        <div class="single-feedback">
            <div class="client-img">
                <slot name="image"></slot>
            </div>

            <h3 v-text="name"></h3>
            <span v-text="title"></span>
            <p><slot></slot></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Feedback",
    props: ['name' ,'title', 'image']
}
</script>
