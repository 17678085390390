<template>
    <div id="how-can-i-get-credit">
        <h3>How can I get credit?</h3>
        <p>With each complete project, you will increase credit for yourself. Then you will be able to put bigger projects without prepaying.</p>
    </div>
</template>

<script>
export default {
    name: 'how-can-i-get-credit'
}
</script>
