<template>
    <aside class="widget-area" id="secondary">
        <!--
            <section class="widget widget_search">
                <form class="search-form">
                    <label>
                        <span class="screen-reader-text">Search for:</span>
                        <input type="search" class="search-field" placeholder="Search...">
                    </label>
                    <button type="submit"><feather type="search"></feather></button>
                </form>
            </section>
        -->

        <section class="widget widget_categories">
            <h3 class="widget-title">Terms Map</h3>
            <ul>
                <li><a href="#welcome">Welcome to Datarivers.org</a></li>
            </ul>
        </section>
    </aside>
</template>

<script>
export default {
    name: 'Sidebar'
}
</script>
