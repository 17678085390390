<template>
    <div id="do-i-need-to-give-you-all-my-server-access-information">
        <h3>Do I need to give you all my server access information?</h3>
        <p>project creates the necessary facilities to buffer access between the developers and the server in such a way that they will not have access to this information.</p>
    </div>
</template>

<script>
export default {
    name: 'do-i-need-to-give-you-all-my-server-access-information'
}
</script>
