<template>
    <div id="lets-say-i-just-have-an-idea-how-can-i-figure-out-who-can-do-what-for-my-idea">
        <h3>Let’s say I just have an idea, how should I figure out “who can do what” for my idea?</h3>
        <p>Implementing a project in the best way requires a lot of IT experience. The method that is suitable for one case is not necessarily suitable for similar projects with a few changes. For this reason, finding a specialist for each task can have a great impact on the project process and the result.</p>
        <p>We create the project form with your help and then distribute it ourselves and leave it to the relevant experts.</p>
    </div>
</template>

<script>
export default {
    name: 'lets-say-i-just-have-an-idea-how-can-i-figure-out-who-can-do-what-for-my-idea'
}
</script>
