<template>
    <div>
        <Banner></Banner>
<!--        <BoxesArea></BoxesArea>-->
        <Services></Services>
        <ServicesArea></ServicesArea>
        <Features></Features>
<!--        <Funfacts></Funfacts>-->
<!--        <Works></Works>-->
<!--        <Pricing></Pricing>-->
        <Feedbacks></Feedbacks>
        <ReadyToTalk></ReadyToTalk>

<!--        <Partner></Partner>-->
<!--        <Blog></Blog>-->
    </div>
</template>

<script>
import Banner from './development/Banner';
// import BoxesArea from './development/BoxesArea';
import Services from './development/Services';
import ServicesArea from './development/ServicesArea';
import Features from './development/Features';
import Funfacts from './development/Funfacts';
// import Works from './development/Works';
// import Pricing from './development/Pricing';
import Feedbacks from './feedback/Feedbacks';
import ReadyToTalk from './development/ReadyToTalk';
export default {
    name: 'Development',
    title: 'Development',
    components: {
        Banner,
        // BoxesArea,
        Services,
        ServicesArea,
        Features,
        Funfacts,
        // Works,
        // Pricing,
        Feedbacks,
        ReadyToTalk,
    }
}
</script>
