<template>
    <div>
<!--        <HeaderTitle>About Us</HeaderTitle>-->
        <Content></Content>
<!--        <Team></Team>-->
<!--        <FeedbacksTwo></FeedbacksTwo>-->
<!--        <Funfacts></Funfacts>-->
<!--        <PartnerTwo></PartnerTwo>-->
    </div>
</template>

<script>
import HeaderTitle from './about-us/HeaderTitle';
import Content from './about-us/Content';
// import Team from './about-us/Team';
// import FeedbacksTwo from './feedback/FeedbacksTwo';
// import Funfacts from './about-us/Funfacts';
// import PartnerTwo from './about-us/PartnerTwo';
export default {
    name: 'AboutStyleThree',
    title: 'About Us',
    components: {
        HeaderTitle,
        Content,
        // Team,
        // FeedbacksTwo,
        // Funfacts,
        // PartnerTwo
    }
}
</script>
