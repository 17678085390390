<template>
    <div>
		<section class="ml-services-area ptb-80">
			<div class="container">
                <div class="section-title">
                    <h2>What We Offer ?</h2>
                    <div class="bar"></div>
                    <p>We offer complete online business services in a concentrated team work</p>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-ml-services-box noclick">
                            <div class="image">
                                <img src="../../../../assets/interface/img/services-image/2.png" alt="image">
                            </div>
                            <h3><a href="#">Web Developers</a></h3>
                            <p>We implement all imaginations, ideas and creativity, backend and frontend</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-ml-services-box noclick">
                            <div class="image">
                                <img src="../../../../assets/interface/img/services-image/1.png" alt="image">
                            </div>
                            <h3><a href="#">Plugin Designers</a></h3>
                            <p>All our designed plugins are tested for highest quality</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-ml-services-box noclick">
                            <div class="image">
                                <img src="../../../../assets/interface/img/services-image/3.png" alt="image">
                            </div>
                            <h3><a href="#">Repair Team</a></h3>
                            <p>We will fix the part of your site that needs to be repaired</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-ml-services-box noclick">
                            <div class="image">
                                <img src="../../../../assets/interface/img/services-image/4.png" alt="image">
                            </div>
                            <h3><a href="#">Security Suppliers</a></h3>
                            <p>We review all designed software for compliance with security protocols</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-ml-services-box noclick">
                            <div class="image">
                                <img src="../../../../assets/interface/img/services-image/5.png" alt="image">
                            </div>
                            <h3><a href="#">Version Controllers</a></h3>
                            <p>We regularly update our softwares in terms of security and integrity</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-ml-services-box noclick">
                            <div class="image">
                              <img src="../../../../assets/interface/img/services-image/6.png" alt="image">
                            </div>
                            <h3><a href="#">Soft-Robot Programmers</a></h3>
                            <p>We design software robots to automate time-consuming and costly processes</p>
                        </div>
                    </div>

<!--                    <div class="col-lg-4 col-sm-6 col-md-6">-->
<!--                        <div class="single-ml-services-box">-->
<!--                            <div class="image">-->
<!--                                <img src="../../../../assets/interface/img/services-image/6.png" alt="image">-->
<!--                            </div>-->
<!--                            <h3><a href="#">Need More ...</a></h3>-->
<!--                            <p></p>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
			</div>

			<div class="shape1"><img src="../../../../assets/interface/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../../assets/interface/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>

            <div class="contact-cta-box">
                <h3>Have any question about us?</h3>
                <p>Don't hesitate to contact us</p>
                <a href="/contact-us" class="btn btn-primary">Contact Us</a>
            </div>

        </section>
        <!-- End ML Services Area -->
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>
