<template>
    <div>
        <Title></Title>

        <section class="blog-details-area ptb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <Description></Description>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <Sidebar></Sidebar>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Title from './terms-main/Title';
import Description from './terms-main/Description';
import Sidebar from './terms-main/Sidebar';

export default {
    name: 'TermsMain',
    title: 'Terms',
    components: {
        Title,
        Description,
        Sidebar
    }
}
</script>
