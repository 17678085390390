<template>
    <header id="header" :class="['headroom', {'navbar-style-two': SolidStyle == 2}, {'navbar-style-three': SolidStyle >= 3}, {'is-sticky': isSticky}]">
        <div class="startp-nav">
            <div :class="[{'container': SolidStyle <= 2},{'container-fluid': SolidStyle >= 3}]">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../../assets/interface/img/logo.svg" alt="logo" height="42px" width="184px"/>
                    </router-link>

                    <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>
                    <topMenu :to-left="SolidStyle == 3" v-if="!isEmpty || this.scrollPosition > 300"></topMenu>

                    <div class="others-option" v-if="!isEmpty">
                    <!--<router-link to="/cart" class="cart-wrapper-btn">
                            <feather type="shopping-cart"></feather>
                            <span>{{shoppingCart.length}}</span>
                        </router-link>-->
                        <a :href="serverRoutes['support']" :class="['btn', {'btn-gradient':GradiantButton},{'btn-light':!GradiantButton}]">Get Support</a>
<!--                        <a :href="serverRoutes['login']" class="btn btn-primary">Login</a>-->
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
import TopMenu from './TopMenu';

export default {
    name: 'Header',
    props:{
        SolidStyle:{
            required: false,
            default: 0,
        },
        isEmpty:{
            required: false,
            default: false,
        },
        GradiantButton:{
            required: false,
            default: false,
        },
    },
    components: {
        TopMenu,
    },
    data(){
        return {
            serverRoutes: {
                'login': "/login",
                'support': "/contact-request",
            },
            isSticky: false,
            scrollPosition: 0,
        }
    },

    mounted() {
        const that = this;
        window.addEventListener('scroll', () => {
            // eslint-disable-next-line no-console
            that.scrollPosition = window.scrollY;
            that.isSticky = (that.scrollPosition >= 300);
        })
    },

    // computed: {
    //     shoppingCart(){
    //         return this.$store.state.cart;
    //     }
    // }
}
</script>
