<template>
    <!-- Start Boxes Area -->
    <section class="boxes-area hosting-boxes-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box">
                        <div class="icon icon2">
                            <feather type="hard-drive"></feather>
                        </div>
                        <h3>Automated Backups</h3>
                        <p>We automatically make two copies of your data to ensure safety & redundancy</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-c679e3">
                        <div class="icon icon2">
                            <feather type="shield"></feather>
                        </div>
                        <h3>L3 Firewall Security</h3>
                        <p>You have an L3 Firewall just before the network interface</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-f78acb">
                        <div class="icon icon2">
                            <feather type="globe"></feather>
                        </div>
                        <h3>Global Infrastructure</h3>
                        <p>We have twelve data centre locations around the world</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-eb6b3d">
                        <div class="icon icon2">
                            <feather type="user"></feather>
                        </div>
                        <h3>Exclusive Support</h3>
                        <p>Our customer support is available to help the clients 24/7/365</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Boxes Area -->
</template>

<script>
export default {
    name: 'BoxesArea'
}
</script>
