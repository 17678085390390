<template>
    <div>
        <Header solid-style="2" v-if="currentUrl.includes('/cloud-hosting')"></Header>
        <Header is-empty="true" v-else-if="currentUrl.includes('/contact-request')"></Header>
        <Header solid-style="3" gradiant-button="true" v-else-if="currentUrl.includes('/graphic-studio')"></Header>
        <Header gradiant-button="true" v-else-if="currentUrl.includes('/cloud-hosting')"></Header>
<!--        <HeaderThree v-else-if="currentUrl == '/machine-learning'"></HeaderThree>-->
        <div v-else-if="currentUrl.includes('/not-found') || currentUrl.includes('/coming-soon')"></div>
        <Header v-else></Header>
        <!--    <PreLoader v-if="isLoading" />-->
        <router-view></router-view>
        <div v-if="currentUrl.includes('/not-found') || currentUrl.includes('/coming-soon')"></div>
        <Footer v-else></Footer>
    </div>
</template>

<script>
import Header from './includes/Header';
import Footer from './includes/Footer';
// import PreLoader from './includes/PreLoader';
export default {
    name: 'app',
    components: {
        Header,
        Footer,
        // PreLoader
    },

    data() {
        return {
            // isLoading: true,
            currentUrl: ''
        }
    },

    watch: {
        '$route'(pathUrl) {
            this.currentUrl = pathUrl.path;
            // this.isLoading = true;
            // setTimeout(() => { this.isLoading = false }, 150);
        }
    },

    mounted() {
        this.currentUrl = window.location.pathname;
        // setTimeout(() => {
        //   this.isLoading = false
        // }, 2000);
    }
}
</script>
