<template>
    <div class="main-banner mini">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row h-100 justify-content-center align-items-center">
                        <div class="col-lg-5">
                            <div class="hero-content">
                                <h1>We Contact You</h1>
                                <p>Submit your contact information, we will contact you as soon as possible</p>
                            </div>
                        </div>

                        <div class="col-lg-5 offset-lg-1">
                            <div v-if="formSubmitted" class="banner-form ml-3">
                                Dear {{ formData.fullname }},<br/><br/>
                                It's nice to meet you.<br/><br/>
                                Thank you for choosing <strong>Datarivers.org</strong><br/>
                                We will contact with you very soon.
                                <br/><br/>
                                Have a good day 😊
                            </div>
                            <div v-else class="banner-form ml-3">
                                <form @submit="SubmitForm">
                                    <div class="form-group">
                                        <label>Full Name</label>
                                        <input type="text" v-model="formData.fullname" class="form-control"
                                               placeholder="Enter your full name"
                                               autofocus required>
                                    </div>

                                    <div class="form-group">
                                        <label>Phone Number</label>
                                        <input type="tel" v-model="formData.mobile" class="form-control"
                                               placeholder="Enter your phone number" required>
                                    </div>

                                    <div class="form-group">
                                        <label>Subject</label>
                                        <input type="text" v-model="formData.subject" class="form-control"
                                               placeholder="Enter your project's subject"
                                               max="150" required>
                                    </div>

                                    <button type="submit" class="btn btn-primary">Call Me</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../../../../assets/interface/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../../../assets/interface/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape5"><img src="../../../../assets/interface/img/shape5.png" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
    </div>
    <!-- End Main Banner -->
</template>

<script>
export default {
    name: 'Banner',
    data() {
        return {
            'formData': {
                'fullname': '',
                'mobile': '',
                'subject': '',
            },
            'formSubmitted': false,
        }
    },
    methods: {
        SubmitForm(e) {
            e.preventDefault();

            axios.post('/face/api/v1/register-request', this.formData)
                .then(response => {
                    console.log(response)
                })
                .catch(error => console.log(error))
                .then(this.formSubmitted = true);
        },
    }

}
</script>
