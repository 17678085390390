<template>
    <section class="iot-main-banner">
        <div class="container">
            <div class="iot-banner-content">
                <h2>We Care</h2>
                <p>At this point, it may seem like overkill to point out the continued growth bound to happen in the Internet of Things space for the years to come and how it will create new opportunities for companies, both established and new, from a hardware and software perspective.</p>
                <a href="/contact-request" class="btn btn-primary">Let's Begin</a>
            </div>

            <div class="iot-banner-image">
                <img src="../../../../assets/interface/img/iot-banner-image/1.png" class="wow fadeInUp" v-wow data-wow-delay="1s" alt="image">
                <img src="../../../../assets/interface/img/iot-banner-image/2.png" class="wow zoomIn" alt="image">
            </div>

            <div class="animate-border">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </section>
    <!-- End IoT Main Banner -->
</template>

<script>
export default {
    name: 'Banner'
}
</script>
