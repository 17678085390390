<template>
    <section class="agency-about-area ptb-80">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="agency-about-img">
                        <img src="../../../../assets/interface/img/agency-image/about-img.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="agency-about-content">
                        <span class="sub-title">About Us</span>
                        <h2>We Are Classmates</h2>
                        <div class="bar"></div>
                        <p>My name is Armin.</p>
                        <p>I am a teacher of digital branding.
                            In my classroom, students are confronted with challenges and problems that occur in real projects,
                            in a controlled environment and we all try to solve these problems in a team atmosphere.</p>
                        <p>During one of the programming courses, I found that I was accompanied by talented students who were very interested in working on technical projects.</p>
                        <p>After completing the course with these students, we decided to provide specialized technical services in the field of web technology. This is how Datarivers was founded.</p>
                        <p>With the completion of each course, more talented students will be able to join our production teams and provide qualified services to businesses.</p>

                        <!--                            <a href="#" class="btn btn-secondary">Discover More</a>-->
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="about-inner-area">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about-text">
                            <h3>Our Misson</h3>
                            <ul>
                                <li>We provide online IT services to webmasters.</li>
                                <li>In providing these services, we act in an integrated way so that our customers do not have to worry about finding an expert for each part of their cases.</li>
                                <li>Provide these services under the quality control of experienced supervisors to provide the highest level of product satisfaction to the customer.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about-text">
                            <h3>Our Vision</h3>
                            <p>To be the world's most trusted partner for the online IT services.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="about-text">
                            <h3>Our Values</h3>
                            <ul>
                                <li>We are a classmates team that values commitment and quality.</li>
                                <li>We trust each other and have fun winning together.</li>
                                <li>We always do the right thing. With precision, pace and passion.</li>
                                <li>We make our chances, own and shape our future.</li>
                                <li>We create sustainable growth. For All.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape2 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../../../assets/interface/img/shape3.svg" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
    </section>
</template>

<script>
export default {
    name: "Content"
}
</script>
