<template>
    <!-- Start Footer Area -->
    <footer class="footer-area bg-f7fafd">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="logo">
                            <a href="/">
                                <img src="../../../assets/interface/img/logo.svg" alt="logo" height="29px" width="125px" />
                            </a>
                        </div>
                        <p style="text-align: justify">To provide <strong>qualified concentrated services</strong> for <strong>online businesses</strong>,
                            the teacher who taught programming to his students,
                            decided to start an expert team with his excellent students.</p>
                            <p>This is how <strong>Datarivers</strong> was born with love.                        </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Offline Support</h3>
                        <ul class="list">
                            <li><a href="/faq">FAQ's</a></li>
<!--                            <li><a href="/privacy-policy">Privacy Policy</a></li>-->
                            <li><a href="/terms/main">Terms & Conditions</a></li>
<!--                            <li><a href="/terms/payments">Terms of Payments</a></li>-->
<!--                            <li><a href="#">Community</a></li>-->
                            <li><a href="/contact-us">Contact Us</a></li>
<!--                            <li><a href="#">Services</a></li>-->
<!--                            <li><a href="#">Features</a></li>-->
<!--                            <li><a href="#">Our Pricing</a></li>-->
<!--                            <li><a href="#">Latest News</a></li>-->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Contact Info</h3>

                        <ul class="footer-contact-info">
<!--                            <li><feather type="map-pin"></feather> 27 Division St, New York, NY 10002, USA</li>-->
                            <li><feather type="mail"></feather> Email: <a href="mailto:support@datarivers.org">support@datarivers.org</a></li>
                            <li><feather type="phone-call"></feather> Phone: <a href="tel:+31645396996">(+31) 6453 96996</a></li>
                        </ul>
<!--                        <ul class="social-links">-->
<!--                            <li><a href="#" class="facebook"><feather type="facebook"></feather></a></li>-->
<!--                            <li><a href="#" class="twitter"><feather type="twitter"></feather></a></li>-->
<!--                            <li><a href="#" class="instagram"><feather type="instagram"></feather></a></li>-->
<!--                            <li><a href="#" class="linkedin"><feather type="linkedin"></feather></a></li>-->
<!--                        </ul>-->
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="copyright-area">
                        <p>Copyright &copy;2020 Datarivers. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>

        <img src="../../../assets/interface/img/map.png" class="map" alt="map">
        <div class="shape1"><img src="../../../assets/interface/img/shape1.png" alt="shape1"></div>
        <div class="shape8 rotateme"><img src="../../../assets/interface/img/shape2.svg" alt="shape2"></div>
        <!-- <div class="go-top"><feather type="arrow-up"></feather></div> -->

<!--        <back-to-top bottom="50px" right="50px">-->
<!--            <div class="go-top"><feather type="arrow-up"></feather></div>-->
<!--        </back-to-top>-->
    </footer>
    <!-- End Footer Area -->
</template>

<script>
// import BackToTop from 'vue-backtotop'
export default {
    name: 'Footer',
    components: { /*BackToTop*/ }
}
</script>
