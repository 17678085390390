<template>
    <div id="key_terms">
        <h3>Key Terms</h3>
        <p>
            <strong>Services</strong> are services offered on Datarivers.<br/>
            <strong>Developers</strong> are Datarivers agents who perform the services.<br/>
            <strong>Clients</strong> are users who purchase services on Datarivers.<br/>
            <strong>Orders</strong> are the formal agreements between a Client and Datarivers after a purchase was made from the Clients Order Page, or after requesting to postpaid by sufficient credit.<br/>
            <strong>Order Page</strong> is where clients can describe their projects and create an order. This page can live public or remain private by owner client’s call.<br/>
            <strong>Communication Page</strong> is where Clients and Developers communicate with each other in connection with an order.<br/>
            <strong>Custom Orders</strong> are requests made by a Client to receive a Custom Offer from us.<br/>
            <strong>Custom Offers</strong> are exclusive proposals that we create in response to specific requirements of a Client.<br/>
            <strong>Order Packages</strong> allow us to offer services in different formats and prices. Packages can include upgrades, which let us price our service for a basic price.<br/>
            <strong>Extra Orders</strong> are additional services requested or offered on top of the project for an additional price defined by the Datarivers.<br/>
        </p>
    </div>
</template>

<script>
export default {
    name: 'KeyTerms'
}
</script>
