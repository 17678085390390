<template>
    <div>
        <Banner></Banner>
        <BoxesArea></BoxesArea>
        <DomainSearch></DomainSearch>
        <WhyChooseUs></WhyChooseUs>
<!--        <Pricing></Pricing>-->
<!--        <Feedback></Feedback>-->
<!--        <Partner></Partner>-->
<!--        <Features></Features>-->
        <ReadyToTalk></ReadyToTalk>
<!--        <Blog></Blog>-->
    </div>
</template>

<script>
import Banner from './web-hosting/Banner';
import BoxesArea from './web-hosting/BoxesArea';
import DomainSearch from './web-hosting/DomainSearch';
import WhyChooseUs from './web-hosting/WhyChooseUs';
// import Pricing from './web-hosting/Pricing';
// import Feedback from './web-hosting/Feedback';
// import Partner from './web-hosting/Partner';
// import Features from './web-hosting/Features';
import ReadyToTalk from './web-hosting/ReadyToTalk';
// import Blog from './web-hosting/Blog';
export default {
    name: 'WebHosting',
    title: 'Cloud Hosting',
    components: {
        Banner,
        BoxesArea,
        DomainSearch,
        WhyChooseUs,
        // Pricing,
        // Feedback,
        // Partner,
        // Features,
        ReadyToTalk,
        // Blog
    }
}
</script>
