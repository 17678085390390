<template>
    <b-collapse class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" is-nav>
        <ul :class="['navbar-nav nav', {'ml-auto': !ToLeft}]">
            <li class="nav-item" v-for="menuItem in menus">
                <router-link :to="menuItem.href" :class="['nav-link', menuItem.class]">
                    {{ menuItem.title }} <feather type="chevron-down" v-show="haveChild(menuItem)"></feather>
                </router-link>
<!--                <a :href="menuItem.href"  :class="['nav-link', menuItem.class]">-->
<!--                    {{ menuItem.title }} <feather type="chevron-down" v-show="haveChild(menuItem)"></feather>-->
<!--                </a>-->

                <ul class="dropdown_menu" v-if="haveChild(menuItem)">
                    <li class="nav-item" v-for="menuSubItem in menuItem.children">
                        <router-link :to="menuSubItem.href" :class="['nav-link' , menuSubItem.class]">
                            {{ menuSubItem.title }}
                        </router-link>
<!--                        <a :href="menuSubItem.href" class="nav-link">-->
<!--                            {{ menuSubItem.title }}-->
<!--                        </a>-->
                    </li>
                </ul>
            </li>
        </ul>
    </b-collapse>
</template>

<script>

export default {
    name: "TopMenu",
    props:{
        ToLeft:{
            required: false,
            default: false,
        }
    },
    methods:{
        haveChild(item){
            return item['children'].length > 0;
        },
    },

    data(){
        return {
            menus: [],
        }
    },

    created() {
        // axios.get('/pub/api/v1/menus/all')
        //     .then(response => this.menus = response.data)
        //     .catch(error => console.log(error));

        this.menus = [
            {
                'title': 'Datarivers',
                'class': '',
                'href' : '/',
                'children': [],
            },
            {
                'title': 'Services',
                'class': '',
                'href' : '',
                'children': [
                    {
                        'title': 'Development',
                        'class': '',
                        'href' : '/services/development',
                        'children': [],
                    },
/*
                    {
                        'title': 'Maintenance',
                        'class': '',
                        'href' : '/services/maintenance',
                        'children': [],
                    },
*/
                    {
                        'title': 'Cloud Hosting',
                        'class': '',
                        'href' : '/services/cloud-hosting',
                        'children': [],
                    },
/*
                    {
                        'title': 'Graphic Studio',
                        'class': '',
                        'href' : '/services/graphic-studio',
                        'children': [],
                    },
*/
                ],
            },
            {
                'title': 'About Us',
                'class': '',
                'href' : '/about-us',
                'children': [],
            },
        ];
    }
}
</script>

<style scoped>

</style>
