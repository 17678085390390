<template>
    <!-- Start Features Area -->
    <section class="features-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>Our Values</h2>
                <div class="bar"></div>
                <p>We are committed to a production consistent with the following value system:</p>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-features">
                        <div class="icon">
                            <feather type="shield"></feather>
                        </div>

                        <h3>Security & Safety</h3>
                        <p>We review all designed software for compliance with security protocols</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-features">
                        <div class="icon">
                            <feather type="calendar"></feather>
                        </div>

                        <h3>Managed Project</h3>
                        <p>We are committed to implementing your project according to the schedule we provide</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-features">
                        <div class="icon bg-c679e3">
                            <feather type="grid"></feather>
                        </div>

                        <h3>Controlling Dashboard</h3>
                        <p>You are able to control your projects state with a simple and powerful dashboard</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-features">
                        <div class="icon bg-c679e3">
                            <feather type="bell"></feather>
                        </div>

                        <h3>Deadline Reminders</h3>
                        <p>To better understand the status of your project, we inform you about deadlines and milestones</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-features">
                        <div class="icon bg-eb6b3d">
                            <feather type="arrow-up-circle"></feather>
                        </div>

                        <h3>Regular Updates</h3>
                        <p>We regularly update our softwares in terms of security and integrity</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-features">
                        <div class="icon bg-eb6b3d">
                            <feather type="check-circle"></feather>
                        </div>

                        <h3>Structured Support System</h3>
                        <p>We support all projects in a structured way, so that from technical experts to supervisors are available to you</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Features Area -->
</template>

<script>
export default {
    name: 'Features'
}
</script>
