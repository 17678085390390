<template>
    <!-- Start Feedback Area -->
    <section class="feedback-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>What users Saying</h2>
                <div class="bar"></div>
<!--                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
            </div>

            <div class="feedback-slides">
                <div class="client-feedback">
                    <div>
                        <slick
                            ref="slick"
                            :options="slickOptions"
                        >
                            <Feedback
                                name="Alex Mostert"
                                title='Owner of "beleggen [dot] info"'
                            >
                                <template v-slot:image><img src="../../../../assets/interface/img/client-image/alex_mostert_95.jpg" alt="alex-mostert" width="95px"></template>
                                <p>Datarivers team helping me for almost a year to grow my business by developing high quality codes and technical solutions.
                                    They have a great mind and know how to change clients dreams into reality.</p>
                                <p>If you are looking for high quality codes against an attractive price then datarivers is your man.</p>
                            </Feedback>

                            <Feedback
                                name="Jess Ding"
                                title='Online designer'
                            >
                                <template v-slot:image><img src="../../../../assets/interface/img/client-image/jess_95.jpg" alt="jess-ding" width="95px"></template>
                                <p>I am using datarivers 5 star service for many months now
                                    and I am extremely happy with their flexibility and customer services.
                                    Many times I had issues with my server during unpleasant hours and they attended to my issues immediately.</p>
                                <p>In holiday or sleep, I feel comfortable as they have an eye on my virtual properties.</p>
                            </Feedback>

                        </slick>
                    </div>
                </div>

                <div class="client-thumbnails">
                    <div>
                        <slick
                            ref = "slick2"
                            :asNavFor = "$refs.slick"
                            :options="slickOptions2"
                        >
                            <div class="item">
                                <div class="img-fill"><img src="../../../../assets/interface/img/client-image/alex_mostert.jpg" alt="client-alex-mostert"></div>
                            </div>

                            <div class="item">
                                <div class="img-fill"><img src="../../../../assets/interface/img/client-image/jess.jpg" alt="client-jess-ding"></div>
                            </div>

<!--                            <div class="item">
                                <div class="img-fill"><img src="../../../../assets/interface/img/client-image/3.jpg" alt="client"></div>
                            </div>-->
                        </slick>
                    </div>

                    <button class="prev-arrow slick-arrow">
                        <feather type="arrow-left"></feather>
                    </button>

                    <button class="next-arrow slick-arrow">
                        <feather type="arrow-right"></feather>
                    </button>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../../../../assets/interface/img/shape1.png" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape5"><img src="../../../../assets/interface/img/shape5.png" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../../assets/interface/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../../assets/interface/img/shape2.svg" alt="shape"></div>
    </section>
    <!-- End Feedback Area -->
</template>

<script>
// import VueSlickCarousel from 'vue-slick-carousel';
import Slick from 'vue-slick';
import Feedback from './Feedback';

import 'slick-carousel/slick/slick.css';
export default {
    name: 'Feedbacks',
    components: {
        Slick,
        Feedback
    },
    data() {
        return {
            slickOptions: {
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                cssEase: 'linear',
                fade: true,
                autoplay: true,
                draggable: true,
                prevArrow: '.client-feedback .prev-arrow',
                nextArrow: '.client-feedback .next-arrow'
            },
            slickOptions2: {
                speed: 300,
                slidesToShow: 5,
                slidesToScroll: 1,
                cssEase: 'linear',
                autoplay: true,
                centerMode: true,
                draggable: false,
                focusOnSelect: true,
                prevArrow: '.client-thumbnails .prev-arrow',
                nextArrow: '.client-thumbnails .next-arrow',
            },
        };
    },

    methods: {
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        }
    }
}
</script>

