<template>
    <div id="how-can-i-follow-my-project">
        <h3>How can I follow my project?</h3>
        <p>During project implementation, we have placed milestones to control the project progress, each with a specific timeline. You can follow these milestones from your project management panel. You can also set alerts to remind you of milestones at a specific time.</p>
    </div>
</template>

<script>
export default {
    name: 'how-can-i-follow-my-project'
}
</script>
