<template>
    <div id="how-can-i-assign-a-project">
        <h3>How can I assign a project?</h3>
        <p>All you have to do is submit your project with the details you need for the best result and send it to us. You can also request a pre-project meeting with our consultants to ensure accurate project statements.</p>
    </div>
</template>

<script>
export default {
    name: 'how-can-i-assign-a-project'
}
</script>
