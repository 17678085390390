<template>
    <div>
        <GetInTouch></GetInTouch>
        <ContactInfo></ContactInfo>

    </div>
</template>

<script>
import HeaderTitle from './contact-us/HeaderTitle'
import GetInTouch from './contact-us/GetInTouch'
import ContactInfo from './contact-us/ContactInfo2'
export default {
    name: 'ContactUs',
    title: 'Contact Us',
    components:{
        HeaderTitle,
        GetInTouch,
        ContactInfo,
    },
}
</script>
