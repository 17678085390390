import Vue from 'vue'
import VueRouter from 'vue-router';
import {BootstrapVue} from 'bootstrap-vue';
import VueFeather from 'vue-feather';
import vWow from 'v-wow';
import VueCarousel from 'vue-carousel';
import Toasted from 'vue-toasted';
import App from '../../views/interface/App.vue'
import './style/custom.scss';
import {router} from './router';
import store from './store';
import axios from "axios";
import titleMixin from './mixins/doctitle';

window.axios = axios;

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(VueFeather);
Vue.use(VueRouter);
Vue.use(vWow);
Vue.use(VueCarousel);
Vue.use(Toasted)

Vue.mixin(titleMixin)

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
