<template>
    <div class="blog-details-desc">
        <div class="article-image">
            <img src="../../../../assets/interface/img/terms/1.png" alt="image">
        </div>
        <div class="article-content">
            <Welcome></Welcome>
        </div>
    </div>
</template>

<script>
import Welcome from './items/01_Welcome'
export default {
    name: 'Description',
    components: {
        Welcome,
    }
}
</script>
